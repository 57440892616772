import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import LayoutPost from "../components/LayoutPost";

const PhotoPost = ({ data }) => {
  return <LayoutPost data={data} />;
};

PhotoPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
};

export default PhotoPost;

export const pageQuery = graphql`
  query PhotosPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      htmlAst
      frontmatter {
        templateKey
        date(formatString: "DD of MMMM YYYY")
        title
        description
        tags
        gallery {
          image {
             full:childImageSharp {
                fluid(maxWidth: 4000, quality: 100) {
                  ...GatsbyImageSharpFluid
                  ...GatsbyImageSharpFluidLimitPresentationSize
                  ...GatsbyImageSharpFluid_noBase64
                }
            }
            responsive: childImageSharp {
              fluid(maxWidth: 1152, quality: 100) {
                ...GatsbyImageSharpFluid
                ...GatsbyImageSharpFluidLimitPresentationSize
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          description
        }
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1152, quality: 100) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`;
